import React, { useEffect, useState } from 'react';
import { BoxLite } from '../../../common/types/box';
import { SearchOrCreateDropdown } from '../../../webui/inputs/SearchOrCreateDropdown';
import { useAllBoxesLite } from '../../box/gql/useAllBoxesLite';

interface Props {
  onChange: (value: string) => void;
  value?: string | undefined;
}

const getUniqueMunicipalities = (boxes: BoxLite[]): string[] => {
  return Array.from(
    new Set(
      boxes
        .map((box) => box.location?.municipality?.trim())
        .filter((municipality): municipality is string => Boolean(municipality)) // Filter out undefined or empty cities
    )
  );
};

export const EditMunicipality: React.FC<Props> = ({ onChange, value }) => {
  const { data: allBoxes } = useAllBoxesLite();
  const [options, setOptions] = useState<string[]>([]);

  const handleChange = (newValue: string) => {
    onChange(newValue);
  };

  useEffect(() => {
    setOptions(getUniqueMunicipalities(allBoxes));
  }, [allBoxes]);

  return (
    <div>
      <SearchOrCreateDropdown
        options={options}
        value={value}
        onChange={handleChange}
        placeholder="Select or type"
        label="Municipality"
      />
    </div>
  );
};
