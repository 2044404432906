import { ProductStatus } from '../../../common/types/productStatus';
import { isAfter, isBefore } from '../../../common/utils/dateUtils';
import { resolveBoxDisplayName } from '../../../common/utils/resolveBoxDisplayName';
import { CompartmentsThatNeedsActionFilter } from '../ActionPageFilter';
import { FilterValue } from '../TableFilter';
import { CompartmentsThatNeedsActionData } from './createCompartmentsThatNeedsActionData';

export const filterCompartmentsThatNeedsActionData = (
  rows: CompartmentsThatNeedsActionData[],
  filter: {
    [K in keyof CompartmentsThatNeedsActionFilter]?: FilterValue<
      CompartmentsThatNeedsActionFilter[K]
    >;
  }
) =>
  rows.filter((row) => {
    let matchesAllFilters = true;

    if (filter.boxName && filter.boxName.value.trim() !== '') {
      matchesAllFilters =
        matchesAllFilters &&
        resolveBoxDisplayName(row.box)
          .toLowerCase()
          .includes(filter.boxName.value.toLowerCase());
    }

    if (filter.locked !== undefined && filter.locked.value !== null) {
      matchesAllFilters =
        matchesAllFilters && row.locked === filter.locked.value;
    }

    if (filter.fromDate !== undefined && filter.fromDate.value) {
      matchesAllFilters =
        matchesAllFilters &&
        !!row.date &&
        isAfter(new Date(row.date), new Date(filter.fromDate.value));
    }

    if (filter.endDate !== undefined && filter.endDate.value) {
      matchesAllFilters =
        matchesAllFilters &&
        !!row.date &&
        isBefore(new Date(row.date), new Date(filter.endDate.value));
    }

    if (filter.products && filter.products.value.length > 0) {
      if (filter.products.include) {
        matchesAllFilters =
          matchesAllFilters &&
          row.product?.id !== undefined &&
          filter.products.value.includes(row.product.id);
      } else {
        matchesAllFilters =
          matchesAllFilters &&
          row.product?.id !== undefined &&
          !filter.products.value.includes(row.product.id);
      }
    }

    if (filter.productStatuses && filter.productStatuses.value.length > 0) {
      if (filter.productStatuses.include) {
        matchesAllFilters =
          matchesAllFilters &&
          filter.productStatuses.value.includes(
            row.productStatus as ProductStatus
          );
      } else {
        matchesAllFilters =
          matchesAllFilters &&
          !filter.productStatuses.value.includes(
            row.productStatus as ProductStatus
          );
      }
    }

    return matchesAllFilters;
  });
