import React, { useEffect, useState } from 'react';

type UsePersistedState<T> = [T, React.Dispatch<React.SetStateAction<T>>];

export const usePersistedState = <T>(
  key: string,
  initialValue: T
): UsePersistedState<T> => {
  const [state, setState] = useState<T>(() => {
    try {
      const storedValue = localStorage.getItem(key);
      return storedValue ? (JSON.parse(storedValue) as T) : initialValue;
    } catch (error) {
      console.error(
        `Error loading state for key "${key}" from localStorage:`,
        error
      );
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(state));
    } catch (error) {
      console.error(
        `Error saving state for key "${key}" to localStorage:`,
        error
      );
    }
  }, [key, state]);

  return [state, setState];
};
