import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import sv from 'date-fns/locale/sv';
import { formatDate } from '../../common/utils/dateUtils';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { Checkbox } from '../../webui/Checkbox';
import { MenuItem } from '../../webui/inputs/MenuItem';
import { Select } from '../../webui/inputs/Select';
import { Switch } from '../../webui/inputs/Switch';
import { TextField } from '../../webui/inputs/TextField';
import { INPUT_WIDTH } from '../../webui/inputs/utils';
import { Typography } from '../../webui/Typography';

export interface FilterOption {
  label: React.ReactNode;
  value: string;
}

export type FilterConfig<T> = {
  [sectionKey: string]: {
    label?: string;
    fields: {
      [K in keyof Partial<T>]:
        | { label: string; type: 'string' }
        | { label: string; type: 'multiSelect'; options: FilterOption[] }
        | { label: string; type: 'boolean' }
        | { label: string; type: 'date' };
    };
  };
};

export type FilterValue<T> = T extends (infer U)[]
  ? { value: U[]; include: boolean } // MultiSelect fields
  : { value: T }; // Single-value fields (string, boolean, date)

type FilterProps<T> = {
  filters: { [K in keyof T]?: FilterValue<T[K]> };
  onClearFilter?: () => void;
  onFilterChange: (filters: { [K in keyof T]?: FilterValue<T[K]> }) => void;
  filtersConfig: FilterConfig<T>;
};

export const TableFilter = <T extends Record<string, unknown>>({
  filters,
  onClearFilter,
  onFilterChange,
  filtersConfig,
}: FilterProps<T>) => {
  const handleChange = (key: keyof T, value: T[keyof T]) => {
    const config = Object.values(filtersConfig)
      .flatMap((section) => Object.entries(section.fields))
      .find(([fieldKey]) => fieldKey === key)?.[1];

    if (!config) return;

    onFilterChange({
      ...filters,
      [key]:
        config.type === 'multiSelect'
          ? {
              value,
              include: (filters[key] as { include: boolean })?.include ?? true,
            }
          : { value },
    });
  };

  const toggleIncludeExclude = (key: keyof T) => {
    onFilterChange({
      ...filters,
      [key]: {
        ...(filters[key] as { value: T[keyof T][]; include: boolean }),
        include: !(filters[key] as { include: boolean }).include,
      },
    });
  };

  const handleBooleanToggle = (key: keyof T) => {
    const currentValue = filters[key]?.value;
    const newValue =
      currentValue === true ? false : currentValue === false ? null : true;
    onFilterChange({
      ...filters,
      [key]: { value: newValue },
    });
  };

  const handleDateChange = (key: keyof T, value: Date | null) => {
    onFilterChange({
      ...filters,
      [key]: { value: value ? formatDate(value) : null },
    });
  };

  return (
    <Box display={'flex'} flexDirection={'column'} gap={1}>
      {Object.entries(filtersConfig).map(([sectionKey, section]) => (
        <div key={sectionKey}>
          {section.label && (
            <>
              <Typography>{section.label}</Typography>
            </>
          )}

          <div style={{ display: 'flex', gap: '1rem', flexWrap: 'wrap' }}>
            {Object.entries(section.fields).map(([key, config]) => {
              const typedKey = key as keyof T;
              const filterData = filters[typedKey];

              if (!filterData) return null;

              return (
                <div
                  key={key}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0.5rem',
                  }}
                >
                  {/* Boolean Checkbox */}
                  {config.type === 'boolean' && (
                    <>
                      <Checkbox
                        indeterminate={filterData.value === null}
                        checked={filterData.value === true}
                        onChange={() => handleBooleanToggle('locked')}
                      />
                      <label>{config.label}</label>
                    </>
                  )}

                  {/* Multi-Select Dropdown with Include/Exclude */}
                  {config.type === 'multiSelect' && (
                    <Box display="flex" flexDirection={'column'}>
                      <Typography>{config.label}</Typography>
                      <Box display="flex" alignItems="center" gap={2}>
                        <Select
                          multiple
                          fullWidth
                          value={filterData.value as T[keyof T][]}
                          onChange={(e) =>
                            handleChange(typedKey, e.target.value as T[keyof T])
                          }
                          displayEmpty
                          renderValue={(selected) =>
                            (selected as string[]).length > 0
                              ? selected.map(
                                  (s) =>
                                    config.options.find(
                                      (option) => option.value === s
                                    )!.label
                                )
                              : 'All'
                          }
                          variant={'outlined'}
                        >
                          {config.options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                        <Box
                          alignItems={'center'}
                          display={'flex'}
                          flexDirection={'row'}
                        >
                          <Typography>Exclude</Typography>
                          <Switch
                            label={'Include'}
                            onChange={() => toggleIncludeExclude(typedKey)}
                            value={(filterData as { include: boolean }).include}
                          />
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {/* Date Picker */}
                  {config.type === 'date' && (
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={sv}
                    >
                      <DatePicker
                        label={config.label}
                        value={
                          filterData.value
                            ? new Date(filterData.value as string)
                            : null
                        }
                        onChange={(value) => handleDateChange(typedKey, value)}
                        sx={{ minWidth: INPUT_WIDTH }}
                      />
                    </LocalizationProvider>
                  )}

                  {/* Text Input */}
                  {config.type === 'string' && (
                    <TextField
                      label={config.label}
                      value={filterData.value as string}
                      onChange={(value) =>
                        handleChange(typedKey, value as T[keyof T])
                      }
                      variant={'outlined'}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ))}
      <Box alignSelf={'flex-end'}>
        <Button onClick={onClearFilter}>Clear filter</Button>
      </Box>
    </Box>
  );
};
