import * as React from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '../../webui/Link';
import { Routes } from '../Routes';

interface Props {
  userId: string;
  linkText: string;
}

export const UserLink: React.FC<Props> = ({ userId, linkText }) => {
  return (
    <Link
      component={RouterLink}
      to={generatePath(Routes.USER, {
        id: userId,
      })}
    >
      {linkText}
    </Link>
  );
};
