import { Location } from '../common/types/location';
import { GQL_LocationFragment } from '../generated/graphql';

export const transformLocation = (location: GQL_LocationFragment): Location => {
  return {
    id: location.id,
    coordinates: location.coordinates ?? undefined,
    description: location.description ?? undefined,
    locality: location.locality ?? undefined,
    municipality: location.municipality ?? undefined,
    name: location.name ?? undefined,
  };
};
