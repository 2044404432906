import React, { useState } from 'react';
import { SearchFilter } from '../../common/filter/SearchFilter';
import { Box } from '../../webui/Box';
import { Paper } from '../../webui/Paper';
import { AllRentals } from './AllRentals';
import { OngoingRentalsTable } from './OngoingRentalsTable';
import { Typography } from '../../webui/Typography';

interface Props {}

export const AllRentalsWithSearch: React.FC<Props> = () => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  const onSearchChange = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };

  return (
    <Box>
      <Paper>
        <OngoingRentalsTable />
      </Paper>
      <Box mb={2} />
      <Paper>
        <Box pl={2} pt={2}>
          <Typography variant="h3" className="title-over-table">All rentals</Typography>
        </Box>
        <Box pl={2} pt={2}>
          <SearchFilter
            label={'Box, product or rented by contains'}
            onSearchChange={onSearchChange}
          />
        </Box>

        {/* Since we have already set a title above, we don't supply a title here */}
        <AllRentals searchTerm={searchTerm} />
      </Paper>
    </Box>
  );
};
