import { BatteryReading } from '../../../common/types/batteryReading';
import { formatDate, parseISO } from '../../../common/utils/dateUtils';
import { filterUndefinedCoordinates } from '../../../common/utils/filterUndefinedCoordinates';
import { BatteryStatusMarker } from '../types/batteryStatusMarker';

export const resolveBatteryStatuses = (
  batteryReadings: BatteryReading[]
): BatteryStatusMarker[] => {
  return filterUndefinedCoordinates(
    batteryReadings.map((batteryReading) => {
      return {
        coordinates: batteryReading.box.location.coordinates,
        id: batteryReading.box.id,
        title: `Box ${batteryReading.box.name}, ${formatDate(
          parseISO(batteryReading.createdAt)
        )}, voltage: ${batteryReading.voltage / 1000}`,
        voltage: batteryReading.voltage,
      };
    })
  );
};
