import { Autocomplete, Box, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import React from 'react';
import { INPUT_WIDTH } from './utils';

interface SearchOrCreateDropdownProps {
  options: string[];
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  label?: string;
  width?: string;
}

export const SearchOrCreateDropdown: React.FC<SearchOrCreateDropdownProps> = ({
  options,
  value,
  onChange,
  placeholder = 'Search or create...',
  label = 'Select Option',
  width,
}) => {
  const handleInputChange = (_: React.SyntheticEvent, newValue: string) => {
    onChange(newValue); // Notify parent when the input changes
  };

  const handleSelectionChange = (
    _: React.SyntheticEvent,
    selectedOption: string | null
  ) => {
    if (selectedOption) {
      onChange(selectedOption); // Notify parent when an option is selected
    }
  };

  return (
    <Box>
      <FormControl
        sx={{
          maxWidth: width ?? INPUT_WIDTH,
          minWidth: width ?? INPUT_WIDTH,
        }}
      >
        <Autocomplete
          freeSolo
          options={options}
          value={value}
          onInputChange={handleInputChange}
          onChange={handleSelectionChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
              variant="standard" // Use the 'standard' variant for the TextField
              fullWidth
            />
          )}
          filterOptions={(options, { inputValue }) =>
            options.filter((option) =>
              option.toLowerCase().includes(inputValue.toLowerCase())
            )
          }
          isOptionEqualToValue={(option, val) => option === val}
        />
      </FormControl>
    </Box>
  );
};
