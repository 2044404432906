import React from 'react';
import { UserLink } from '../../common/router_links/UserLink';
import { toDisplayDateTime } from '../../common/utils/dateUtils';
import { SortablePaginatedTable } from '../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../webui/table/sortable-paginated-table/TableHeadCell';
import { BatteryPerBoxData } from './utils/generateBatteryPerBoxData';

interface Props {
  readings: BatteryPerBoxData[];
}

export interface BoxBatteryReadingData {
  id: string;
  date: string;
  user: string;
  voltage: number;
}

const batteryReadingHeadCells: TableHeadCell<BoxBatteryReadingData>[] = [
  {
    id: 'date',
    label: 'Date',
    render: (row) => toDisplayDateTime(row.date),
  },
  {
    id: 'voltage',
    label: 'Voltage',
    render: (row) => row['voltage'],
  },
  {
    id: 'user',
    label: 'User',
    render: (row) => (
      <UserLink userId={row.user} linkText={row.user.slice(0, 5) + '...'} />
    ),
  },
];

const INITIAL_ROWS_PER_PAGE = 20;

const createRows = (readings: BatteryPerBoxData[]): BoxBatteryReadingData[] => {
  return readings.map((reading) => ({
    id: String(reading.date),
    date: reading.date,
    voltage: reading.voltage,
    user: reading.user,
  }));
};

export const BoxBatteryReadingsTable: React.FC<Props> = ({ readings }) => {
  return (
    <SortablePaginatedTable
      title={'Battery readings'}
      initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
      initialOrder={'desc'}
      initialOrderByKey={'date'}
      tableHeadCells={batteryReadingHeadCells}
      rows={createRows(readings)}
    />
  );
};
