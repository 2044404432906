import * as React from 'react';
import { Box } from '../../webui/Box';
import { Typography } from '../../webui/Typography';

export interface LocationTranslationProps {
  description: string;
  lng: 'da' | 'en' | 'no' | 'sv';
  name: string;
}

export const LocationTranslation: React.FC<LocationTranslationProps> = ({
  description,
  lng,
  name,
}) => (
  <Box mt={2} display={'flex'} alignItems={'center'}>
    <img
      src={`/flags/flag-${lng}.svg`}
      alt={`flag-${lng}`}
      width={20}
      height={20}
    />
    <Box ml={1}>
      <Typography variant="body2">Name: {name}</Typography>
      <Typography variant="body2">Description: {description}</Typography>
    </Box>
  </Box>
);
