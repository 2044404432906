import { getAuth } from 'firebase/auth';
import React, { useCallback, useEffect, useState } from 'react';
import { useNotification } from '../../common/notifications/useNotification';
import { Box } from '../../webui/Box';
import { ButtonWithLoading } from '../../webui/ButtonWithLoading';
import {
  CheckboxWithLabel,
  CheckboxWithLabelProps,
} from '../../webui/inputs/CheckboxWithLabel';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { TranslationEditor } from './TranslationEditor';

interface Props {}

export const EditTranslationsPage: React.FC<Props> = () => {
  const [languages, setLanguages] = useState<
    Array<{ id: string; label: string }>
  >([]);
  const [initialTranslations, setInitialTranslations] = useState<
    Record<string, Object | undefined>
  >({});
  const [translations, setTranslations] = useState<
    Record<string, string | undefined>
  >({});
  const [translationsLoading, setTranslationsLoading] = useState<number>(0);
  const [savingTranslations, setSavingTranslations] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState<
    Record<string, boolean>
  >({});

  const { showNotification } = useNotification();

  // Fetch available languages dynamically (can be from API or config file)
  useEffect(() => {
    async function fetchLanguages() {
      const availableLanguages = [
        { id: 'en', label: 'English' },
        { id: 'sv', label: 'Swedish' },
        { id: 'no', label: 'Norwegian' },
        { id: 'da', label: 'Danish' },
        // Add more languages here dynamically if needed
      ];
      setLanguages(availableLanguages);
      setSelectedLanguages(
        availableLanguages.reduce(
          (acc, lang) => ({ ...acc, [lang.id]: true }),
          {}
        )
      );
    }

    fetchLanguages();
  }, []);

  useEffect(() => {
    async function fetchTranslations(language: string) {
      setTranslationsLoading((n) => n + 1);
      const token = await getAuth().currentUser?.getIdToken();

      const resp = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/translations`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            'accept-language': language,
          },
        }
      );
      const json = await resp.json();

      setInitialTranslations((t) => ({
        ...t,
        [language]: JSON.parse(json) ?? {},
      }));
      setTranslationsLoading((n) => n - 1);
    }

    languages.forEach((lang) => fetchTranslations(lang.id));
  }, [languages]);

  const handleChange = useCallback(
    (lang: string) => (json: any) => {
      setTranslations((t) => ({
        ...t,
        [lang]: json,
      }));
    },
    []
  );

  if (translationsLoading > 0) {
    return <LoadingSpinnerContainer />;
  }

  const handleChangeSelectedLanguage =
    (lang: string): CheckboxWithLabelProps['onChange'] =>
    (selected) => {
      setSelectedLanguages((langs) => ({
        ...langs,
        [lang]: selected,
      }));
    };

  const onSave = async () => {
    setSavingTranslations(true);
    const token = await getAuth().currentUser?.getIdToken();

    const updatedTranslations = Object.entries(translations).filter(
      ([lang, translations]) => selectedLanguages[lang] && translations !== null
    );

    for (const [lang, translation] of updatedTranslations) {
      const resp = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/api/setTranslations`,
        {
          method: 'POST',
          headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            language: lang,
            translations: JSON.stringify(translation),
          }),
        }
      );

      if (resp.status === 200) {
        showNotification({
          message: `${lang} updated!`,
          severity: 'success',
        });

        setInitialTranslations((t) => ({
          ...t,
          [lang]: translations[lang],
        }));
        setTranslations((t) => ({
          ...t,
          [lang]: undefined,
        }));
      } else {
        showNotification({
          message: `Something went wrong when saving ${lang}`,
          severity: 'error',
        });
      }
    }

    setSavingTranslations(false);
  };

  return (
    <Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        p={2}
        justifyContent={'space-between'}
      >
        <Box>
          {languages.map((lang) => (
            <CheckboxWithLabel
              key={`lang-checkbox-${lang.id}`}
              onChange={handleChangeSelectedLanguage(lang.id)}
              value={selectedLanguages[lang.id]}
              label={lang.label}
            />
          ))}
        </Box>
        <ButtonWithLoading
          loading={savingTranslations}
          disabled={languages.every(
            (lang) =>
              !selectedLanguages[lang.id] || translations[lang.id] === null
          )}
          onClick={onSave}
        >
          Save
        </ButtonWithLoading>
      </Box>
      <Box display={'flex'} flexDirection={'row'} flex={1}>
        {Object.entries(selectedLanguages).map(([lang, enabled]) => {
          const jsonValues = initialTranslations[lang];
          if (jsonValues === null) {
            return null;
          }

          return (
            <TranslationEditor
              hasChanges={translations[lang] !== undefined}
              json={jsonValues}
              label={languages?.find((l) => l.id === lang)?.label ?? ''}
              lang={lang}
              onChange={handleChange}
              enabled={enabled}
            />
          );
        })}
      </Box>
    </Box>
  );
};
