import { GQL_AllRentalsQueryVariables } from '../../../generated/graphql';
import { type ProductReplacerRentalFilter as ProductReplacerRentalFilterType } from './types';

export const transformProductReplacerFilterToAllRentalsGQLFilter = (
  filter: ProductReplacerRentalFilterType | undefined
): GQL_AllRentalsQueryVariables['filter'] => ({
  endDate:
    filter?.endDate || filter?.fromDate
      ? {
          after: filter.fromDate,
          before: filter.endDate,
        }
      : undefined,
  boxIds:
    filter?.selectedBoxes && filter.selectedBoxes.length > 0
      ? filter.selectedBoxes.map((box) => box.value)
      : undefined,
});
