import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BoxLink } from '../../../common/router_links/BoxLink';
import { CompartmentLink } from '../../../common/router_links/CompartmentLink';
import { ProductLink } from '../../../common/router_links/ProductLink';
import { Rental } from '../../../common/types/rental';
import { getEvaluatedRentalsData } from './getEvaluatedRentalsData';

interface Props {
  endDate: Date;
  fromDate: Date;
  rentals: Rental[];
}

const getRowColor = (score: number) => {
  if (score >= 0.8) return '#FFE6E4';
  if (score >= 0.6) return '#fff3e0';
  if (score >= 0.4) return '#fffde7';
  return '#e8f5e9';
};

export const ProductReplacementEvaluation: React.FC<Props> = ({
  rentals,
  fromDate,
  endDate,
}) => {
  const { t } = useTranslation();
  const evaluated = getEvaluatedRentalsData(rentals, fromDate, endDate);

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {t('productReplacer.table.title', 'Evaluated Product List')}
          </Typography>
          <Typography variant="body2" color="textSecondary" mb={2}>
            {t(
              'productReplacer.table.description.intro',
              'This table helps identify products that may need replacement. It is based on:'
            )}
            <ul>
              <li>
                {t(
                  'productReplacer.table.description.list.0',
                  'How often the product has been rented'
                )}
              </li>
              <li>
                {t(
                  'productReplacer.table.description.list.1',
                  'How long the product has been used in total'
                )}
              </li>
              <li>
                {t(
                  'productReplacer.table.description.list.2',
                  'How frequently it is rented per day (based on the selected date range)'
                )}
              </li>
              <li>
                {t('productReplacer.table.description.list.3', 'User ratings')}
              </li>
              <li>
                {t(
                  'productReplacer.table.description.list.4',
                  'How long ago it was last rented'
                )}
              </li>
            </ul>
            {t(
              'productReplacer.table.description.note',
              'Higher scores mean the product is more likely to need replacement.'
            )}
          </Typography>
          {rentals.length > 0 ? (
            <Box sx={{ overflowX: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {t('productReplacer.table.column.box', 'Box')}
                    </TableCell>
                    <TableCell>
                      {t(
                        'productReplacer.table.column.compartment',
                        'Compartment'
                      )}
                    </TableCell>
                    <TableCell>
                      {t('productReplacer.table.column.product', 'Product')}
                    </TableCell>
                    <TableCell>
                      {t('productReplacer.table.column.rentals', 'Rentals')}
                    </TableCell>
                    <TableCell>
                      {t(
                        'productReplacer.table.column.totalTime',
                        'Total Time'
                      )}
                    </TableCell>
                    <TableCell>
                      {t(
                        'productReplacer.table.column.rentalsPerDay',
                        'Rentals/Day'
                      )}
                    </TableCell>
                    <TableCell>
                      {t('productReplacer.table.column.rating', 'Rating')}
                    </TableCell>
                    <TableCell>
                      {t(
                        'productReplacer.table.column.daysSinceLastRental',
                        'Days Since Last Rental'
                      )}
                    </TableCell>
                    <TableCell>
                      {t(
                        'productReplacer.table.column.replacementScore',
                        'Replacement Score'
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {evaluated.map((e, index) => (
                    <TableRow
                      key={index}
                      style={{
                        backgroundColor: getRowColor(e.replacementScore!),
                      }}
                    >
                      <TableCell>
                        <BoxLink boxId={e.box.id} linkText={e.box.name} />
                      </TableCell>
                      <TableCell>
                        <CompartmentLink
                          boxId={e.box.id}
                          compartmentId={e.compartment.id}
                          linkText={e.compartment.name || ''}
                        />
                      </TableCell>
                      <TableCell>
                        <ProductLink product={e.product} />
                      </TableCell>
                      <TableCell>{e.rentals}</TableCell>
                      <TableCell>{e.totalRentalTime}</TableCell>
                      <TableCell>{e.rentalsPerDay.toFixed(2)}</TableCell>
                      <TableCell>{e.averageRating.toFixed(1)}</TableCell>
                      <TableCell>{e.daysSinceLastRental}</TableCell>
                      <TableCell>{e.replacementScore!.toFixed(2)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          ) : (
            <Box p={2} bgcolor={'#FFE6E4'}>
              <Typography color={'#800F10'}>
                {t(
                  'productReplacer.table.description.noRentals',
                  'There are no rentals for the selected filter'
                )}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {t(
              'productReplacer.details.title',
              'Detailed Explanation of Factors and Weighting'
            )}
          </Typography>
          <Typography variant="body2" mb={2}>
            <strong>{t('productReplacer.details.notePrefix', 'Note')}:</strong>{' '}
            {t(
              'productReplacer.details.noteText',
              'All rentals of 1 minute or less are filtered out.'
            )}
          </Typography>
          <Box sx={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t('productReplacer.details.column.factor', 'Factor')}
                  </TableCell>
                  <TableCell>
                    {t(
                      'productReplacer.details.column.description',
                      'Description'
                    )}
                  </TableCell>
                  <TableCell>
                    {t('productReplacer.details.column.weight', 'Weight')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {t(
                      'productReplacer.details.rows.rentals.label',
                      'Number of Rentals'
                    )}
                  </TableCell>
                  <TableCell>
                    {t(
                      'productReplacer.details.rows.rentals.description',
                      'Lower values indicate low usage'
                    )}
                  </TableCell>
                  <TableCell>0.3</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t(
                      'productReplacer.details.rows.duration.label',
                      'Total Rental Duration'
                    )}
                  </TableCell>
                  <TableCell>
                    {t(
                      'productReplacer.details.rows.duration.description',
                      'Lower values indicate limited usage'
                    )}
                  </TableCell>
                  <TableCell>0.2</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t(
                      'productReplacer.details.rows.perDay.label',
                      'Rentals per Day'
                    )}
                  </TableCell>
                  <TableCell>
                    {t(
                      'productReplacer.details.rows.perDay.description',
                      'Low frequency of usage over time'
                    )}
                  </TableCell>
                  <TableCell>0.3</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t(
                      'productReplacer.details.rows.rating.label',
                      'Average Rating'
                    )}
                  </TableCell>
                  <TableCell>
                    {t(
                      'productReplacer.details.rows.rating.description',
                      'Low user satisfaction'
                    )}
                  </TableCell>
                  <TableCell>0.1</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    {t(
                      'productReplacer.details.rows.days.label',
                      'Days Since Last Rental'
                    )}
                  </TableCell>
                  <TableCell>
                    {t(
                      'productReplacer.details.rows.days.description',
                      'Longer time may indicate unnoticed issues'
                    )}
                  </TableCell>
                  <TableCell>0.1</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
