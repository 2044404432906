import { Product } from '../../../common/types/product';

export type CompartmentSize = 'S' | 'M' | 'L' | 'XL';

type ProductWithSize = { sizes: CompartmentSize[]; translationKey: string };

export const productWithSizeList: ProductWithSize[] = [
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'aerobie' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'snow_mattress' },
  { sizes: ['M', 'L', 'XL'], translationKey: 'american_football' },
  { sizes: ['XL'], translationKey: 'baby_cruiser' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'backnine' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'balance_board' },
  { sizes: ['M', 'L', 'XL'], translationKey: 'basketball' },
  { sizes: ['L', 'XL'], translationKey: 'boardslide' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'boccia' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'boule' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'rounders_kit' },
  { sizes: ['XL'], translationKey: 'cornhole' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'disc_golf' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'foam_roller' },
  { sizes: ['M', 'L', 'XL'], translationKey: 'football' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'football_croquet' },
  { sizes: ['M', 'L', 'XL'], translationKey: 'free_weights' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'frisbee' },
  { sizes: ['M', 'L', 'XL'], translationKey: 'four_in_a_row' },
  {
    sizes: ['XL'],
    translationKey: 'gym_kit_freeweights_medicineball_foamroller',
  },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'handball' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'jumping_rope' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'yatzy' },
  { sizes: ['XL'], translationKey: 'kan_jam' },
  { sizes: ['M', 'L', 'XL'], translationKey: 'kettlebell' },
  { sizes: ['L', 'XL'], translationKey: 'kickbike' },
  { sizes: ['M', 'L', 'XL'], translationKey: 'kick_it' },
  { sizes: ['XL'], translationKey: 'krocket' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'kubb' },
  { sizes: ['L', 'XL'], translationKey: 'medicine_ball' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'mini_ski_skates' },
  { sizes: ['M', 'L', 'XL'], translationKey: 'soft_tennis' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'molkky' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'padel' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'table_tennis' },
  { sizes: ['XL'], translationKey: 'plockepinn' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'ring_game' },
  { sizes: ['M', 'L', 'XL'], translationKey: 'bucket_and_shovel' },
  { sizes: ['XL'], translationKey: 'skateboard' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'slackline' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'snowman_set' },
  { sizes: ['XL'], translationKey: 'sno_kids_ski_set' },
  { sizes: ['XL'], translationKey: 'snow_kick' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'speedminton' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'roundnet' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'bum_slider' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'beach_tennis' },
  { sizes: ['M', 'L', 'XL'], translationKey: 'super_jenga' },
  { sizes: ['M', 'L', 'XL'], translationKey: 'tennis' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'resistance_band' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'trx_band' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'voli_paddle' },
  { sizes: ['M', 'L', 'XL'], translationKey: 'volleyball' },
  { sizes: ['XL'], translationKey: 'wingman_hitman' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'wingman_pro' },
  { sizes: ['XL'], translationKey: 'yoga_mattress' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'beach_football' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'beach_handball' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'beanbags' },
  { sizes: ['XL'], translationKey: 'bobby_bob' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'bucket_and_shovel' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'chess' },
  { sizes: ['M', 'L', 'XL'], translationKey: 'firewood' },
  { sizes: ['XL'], translationKey: 'floorball' },
  { sizes: ['XL'], translationKey: 'hobby_horse' },
  { sizes: ['M', 'L', 'XL'], translationKey: 'life_jacket' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'long_jumping_rope' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'mini_beach_lacrosse' },
  { sizes: ['XL'], translationKey: 'minigolf' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'pickleball' },
  { sizes: ['XL'], translationKey: 'plockepinn' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'resistance_bands' },
  { sizes: ['XL'], translationKey: 'shuffle_curl' },
  { sizes: ['XL'], translationKey: 'snow_rider' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'training_jumping_rope' },
  { sizes: ['XL'], translationKey: 'you_fo' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'flungle' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'flyer' },
  { sizes: ['S', 'M', 'L', 'XL'], translationKey: 'battlebird' },
];

export const hasSize = (translationKey: string, size: CompartmentSize) => {
  const activity = productWithSizeList.find(
    (item) => item.translationKey === translationKey
  );
  if (activity) {
    return activity.sizes.includes(size);
  }
  return false;
};

export const getSelectableProducts = (
  numberOfCompartments: number,
  compartmentIndex: number | null,
  products: Product[]
): Product[] => {
  if (compartmentIndex === null) {
    return products;
  }
  const size = getCompartmentSize(numberOfCompartments, compartmentIndex);
  return products.filter((product) =>
    product.translationKey ? hasSize(product.translationKey, size) : false
  );
};

const getCompartmentSize = (
  numberOfCompartments: number,
  index: number
): CompartmentSize => {
  if (numberOfCompartments === 14) {
    if ([0, 1, 2, 3, 8, 9].includes(index)) return 'S'; // Smallest height
    if ([4, 5, 10, 11].includes(index)) return 'M'; // Small height
    if ([6, 7].includes(index)) return 'L'; // Middle height
    if ([12, 13].includes(index)) return 'XL'; // Big height
  }
  return 'M'; // Default height
};
