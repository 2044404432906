import * as L from 'leaflet';
import { ActivityAreaMarker } from '../types/activityAreaMarker';

function getIconUrl(type: string) {
  switch (type) {
    case 'american_football':
      return '/markers/activity-areas/american_football.svg';
    case 'bandy':
      return '/markers/activity-areas/bandy.svg';
    case 'basketball':
      return '/markers/activity-areas/basketball.svg';
    case 'beachvolleyball':
      return '/markers/activity-areas/beachvolleyball.svg';
    case 'boule':
      return '/markers/activity-areas/boule.svg';
    case 'disc_golf':
      return '/markers/activity-areas/disc_golf.svg';
    case 'field_hockey':
      return '/markers/activity-areas/field_hockey.svg';
    case 'floorball':
      return '/markers/activity-areas/floorball.svg';
    case 'football':
      return '/markers/activity-areas/football.svg';
    case 'gym':
      return '/markers/activity-areas/gym.svg';
    case 'handball':
      return '/markers/activity-areas/handball.svg';
    case 'multiarena':
      return '/markers/activity-areas/multiarena.svg';
    case 'padel':
      return '/markers/activity-areas/padel.svg';
    case 'tennis':
      return '/markers/activity-areas/tennis.svg';
    case 'volleyball':
      return '/markers/activity-areas/volleyball.svg';
    case 'ice_hockey':
      return '/markers/activity-areas/ice_hockey.svg';
    case 'skateboard':
      return '/markers/activity-areas/skateboard.svg';
    case 'table_tennis':
      return '/markers/activity-areas/table_tennis.svg';
    case 'chess':
    case 'ice_skating':
    case 'land_hockey':
    case 'obstacle_course':
    case 'playground':
    case 'rugby':
    case 'track_and_field':
    case 'trampoline':
    default:
      return '/markers/activity-areas/unknown_activity.svg';
  }
}

export const getActivityAreaMarkerIcon = (marker: ActivityAreaMarker) => {
  return L.icon({
    iconUrl: getIconUrl(marker.type),
    iconAnchor: [12, 41],
    popupAnchor: [0, -45],
    iconSize: [30, 42],
  });
};
