import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Avatar } from '@mui/material';
import React from 'react';
import { Accordion } from '../../webui/Accordion';
import { AccordionDetails } from '../../webui/AccordionDetails';
import { AccordionSummary } from '../../webui/AccordionSummary';
import { Box } from '../../webui/Box';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { useAllProducts } from '../product/gql/useAllProducts';
import {
  FilterConfig,
  FilterOption,
  FilterValue,
  TableFilter,
} from './TableFilter';

type ProductStatus =
  | 'Ok'
  | 'MissingParts'
  | 'Dirty'
  | 'Broken'
  | 'Lost'
  | 'Other';

export type CompartmentThatNeedsActionFilterState = {
  [K in keyof CompartmentsThatNeedsActionFilter]?: FilterValue<
    CompartmentsThatNeedsActionFilter[K]
  >;
};

export interface CompartmentsThatNeedsActionFilter {
  boxName: string;
  endDate: string;
  fromDate: string;
  locked: boolean | null;
  products: string[];
  productStatuses: ProductStatus[];
}

interface Props {
  filter: CompartmentThatNeedsActionFilterState;
  onClearFilter?: () => void;
  setFilter: React.Dispatch<
    React.SetStateAction<CompartmentThatNeedsActionFilterState>
  >;
}

const createFilterConfig: (
  productsOptions: FilterOption[]
) => FilterConfig<CompartmentsThatNeedsActionFilter> = (productsOptions) => ({
  sectionBox: {
    label: undefined,
    fields: {
      boxName: { label: 'Box Name', type: 'string' },
    },
  },
  sectionLocked: {
    label: undefined,
    fields: {
      locked: { label: 'Locked?', type: 'boolean' },
    },
  },
  sectionDate: {
    label: 'Dates',
    fields: {
      fromDate: { label: 'From', type: 'date' },
      endDate: { label: 'To', type: 'date' },
    },
  },
  sectionProducts: {
    label: undefined,
    fields: {
      products: {
        label: 'Products',
        type: 'multiSelect',
        options: productsOptions,
      },
    },
  },
  sectionProductStatuses: {
    label: undefined,
    fields: {
      productStatuses: {
        label: 'Product Statuses',
        type: 'multiSelect',
        options: [
          { label: 'Ok', value: 'Ok' },
          { label: 'Missing Parts', value: 'MissingParts' },
          { label: 'Dirty', value: 'Dirty' },
          { label: 'Broken', value: 'Broken' },
          { label: 'Lost', value: 'Lost' },
          { label: 'Other', value: 'Other' },
        ],
      },
    },
  },
});

export const ActionPageFilter: React.FC<Props> = ({
  onClearFilter,
  filter,
  setFilter,
}) => {
  const { data: products, loading: productsLoading } = useAllProducts();

  const productsOptions: FilterOption[] = products
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((product) => ({
      label: (
        <Box display="flex" flexDirection="row" alignItems={'center'}>
          <Avatar
            alt={product.name}
            src={`/products/${product.imageName}.svg`} // Updated image path
            style={{ marginRight: 8 }}
          />
          <Box ml={1} />
          <Typography>{product.name}</Typography>
        </Box>
      ),
      value: product.id,
    }));

  if (productsLoading) {
    return <LoadingSpinnerContainer />;
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="filter-content"
        id="filter-header"
      >
        <Typography variant="h6">Filter</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableFilter
          filtersConfig={createFilterConfig(productsOptions)}
          filters={filter}
          onClearFilter={onClearFilter}
          onFilterChange={setFilter}
        />
      </AccordionDetails>
    </Accordion>
  );
};
