import { Rental } from '../../../common/types/rental';

export interface EvaluatableRental {
  box: { id: string; name: string };
  compartment: { id: string; name?: string };
  product: { id: string; name: string };
  rentals: number;
  totalRentalTime: number;
  rentalsPerDay: number;
  averageRating: number;
  daysSinceLastRental: number;
}

export const normalize = (value: number, min: number, max: number): number => {
  if (max === min) return 0;
  return (value - min) / (max - min);
};

export const transformToEvaluatableRentals = (
  rentals: Rental[],
  fromDate: Date,
  toDate: Date
): EvaluatableRental[] => {
  const filteredRentals = rentals.filter((r) => (r.rentTime ?? 0) > 1);

  const latestByCompartment = new Map<string, Rental>();

  for (const rental of filteredRentals) {
    const key = `${rental.box.id}|${rental.compartment.id}`;
    const current = latestByCompartment.get(key);
    if (!current || new Date(rental.rentStart) > new Date(current.rentStart)) {
      latestByCompartment.set(key, rental);
    }
  }

  const grouped = new Map<string, Rental[]>();

  for (const rental of filteredRentals) {
    const key = `${rental.box.id}|${rental.compartment.id}`;
    if (latestByCompartment.get(key)?.product.id !== rental.product.id)
      continue;

    const groupKey = `${rental.box.id}|${rental.compartment.id}|${rental.product.id}`;
    if (!grouped.has(groupKey)) grouped.set(groupKey, []);
    grouped.get(groupKey)!.push(rental);
  }

  const today = new Date();
  const result: EvaluatableRental[] = [];

  Array.from(grouped.entries()).forEach(([_, group]) => {
    const box = group[0].box;
    const compartment = group[0].compartment;
    const product = group[0].product;
    const totalRentalTime = group.reduce(
      (sum, r) => sum + (r.rentTime ?? 0),
      0
    );
    const rentals = group.length;

    const daysInPeriod = Math.max(
      1,
      (toDate.getTime() - fromDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    const rentalsPerDay = rentals / daysInPeriod;

    const ratings = group
      .map((r) => r.review?.rating)
      .filter((r): r is number => typeof r === 'number');
    const averageRating = ratings.length
      ? ratings.reduce((a, b) => a + b) / ratings.length
      : 5;

    const lastRentalDate = group
      .map((r) => new Date(r.rentEnd || r.rentStart))
      .sort((a, b) => b.getTime() - a.getTime())[0];
    const daysSinceLastRental = Math.round(
      (today.getTime() - lastRentalDate.getTime()) / (1000 * 60 * 60 * 24)
    );

    result.push({
      box,
      compartment,
      product,
      rentals,
      totalRentalTime,
      rentalsPerDay,
      averageRating,
      daysSinceLastRental: daysSinceLastRental,
    });
  });

  return result;
};
