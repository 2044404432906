import * as React from 'react';
import { BoxLink } from '../../common/router_links/BoxLink';
import { toDisplayDate } from '../../common/utils/dateUtils';
import { resolveProductStatusDisplayName } from '../../common/utils/resolveProductStatusDisplayName';
import { Box } from '../../webui/Box';
import { LockIcon } from '../../webui/icons/LockIcon';
import { Table } from '../../webui/table/Table';
import { TableBody } from '../../webui/table/TableBody';
import { TableCell } from '../../webui/table/TableCell';
import { TableContainer } from '../../webui/table/TableContainer';
import { TableHead } from '../../webui/table/TableHead';
import { TableRow } from '../../webui/table/TableRow';
import { Typography } from '../../webui/Typography';
import { ActionsMarker } from './types/actionsMarker';

interface Props {
  marker: ActionsMarker;
}

export const ActionsPopupContent: React.FC<Props> = ({ marker }) => {
  return (
    <Box>
      <Box>
        <BoxLink boxId={marker.boxId} linkText={marker.boxDisplayName} />
        <Typography variant={'subtitle2'}>
          Number of compartments: {marker.numberOfCompartments}
        </Typography>
        <Box>
          <TableContainer>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Comp</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Locked</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {marker.actions.map((action, index) => (
                  <TableRow key={index}>
                    <TableCell>{action.compartmentName}</TableCell>
                    <TableCell>{toDisplayDate(action.date)}</TableCell>
                    <TableCell>{action.productName}</TableCell>
                    <TableCell>
                      {action.productStatus
                        ? resolveProductStatusDisplayName(action.productStatus)
                        : ''}
                    </TableCell>
                    <TableCell>
                      {action.locked ? <LockIcon fill={'#334B55'} /> : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};
