import React from 'react';
import { Marker, Popup } from 'react-leaflet';
import { slottsskogenPosition } from '../../common/utils/slottsskogenPosition';
import { Box } from '../../webui/Box';
import { GenericMap, ZOOM_MANY_MARKERS } from '../location/map/GenericMap';
import { ActionsPopupContent } from './ActionsPopupContent';
import { resolveActionIcon } from './resolveActionIcon';
import { ActionsMarker } from './types/actionsMarker';
import { resolveActionLevel } from './utils/resolveActionLevel';

interface Props {
  actions: ActionsMarker[];
}

export const ActionsMap: React.FC<Props> = ({ actions }) => {
  const actionMarkers = actions;
  return (
    <Box>
      <GenericMap
        initialPosition={
          actionMarkers.length > 0
            ? [
                actionMarkers[0].coordinates.lat,
                actionMarkers[0].coordinates.long,
              ]
            : slottsskogenPosition
        }
        zoom={ZOOM_MANY_MARKERS}
        height={'800px'}
      >
        {actionMarkers.map((marker) => (
          <Marker
            icon={resolveActionIcon(resolveActionLevel(marker))}
            key={marker.id}
            position={[marker.coordinates.lat, marker.coordinates.long]}
          >
            <Popup minWidth={500}>
              <ActionsPopupContent marker={marker} />
            </Popup>
          </Marker>
        ))}
      </GenericMap>
    </Box>
  );
};
