import React from 'react';
import { GQL_SearchType } from '../../generated/graphql';
import { Box } from '../../webui/Box';
import { Typography } from '../../webui/Typography';
import { useAllRentals } from './gql/useAllRentals';
import { RentalsTable } from './RentalsTable';

interface Props {
  searchTerm?: string;
  title?: string;
}

const paginationLimit = 1000;

export const AllRentals: React.FC<Props> = ({ searchTerm, title }) => {
  const {
    data: { rentals, totalCount },
    loading,
    error,
    fetchMore,
  } = useAllRentals({
    variables: {
      pagination: {
        offset: 0,
        limit: paginationLimit,
      },
      search: searchTerm
        ? {
            box: {
              query: searchTerm,
              type: GQL_SearchType.Contains,
            },
            product: {
              query: searchTerm,
              type: GQL_SearchType.Contains,
            },
            rentByUser: {
              query: searchTerm,
              type: GQL_SearchType.Contains,
            },
          }
        : undefined,
    },
  });

  const onFetchMoreClicked = async () => {
    await fetchMore({
      variables: {
        pagination: {
          offset: rentals.length,
          limit: paginationLimit,
        },
      },
    });
  };

  const hasReachedPenultimatePage = async () => {
    await onFetchMoreClicked();
  };

  return (
    <Box>
      <RentalsTable
        error={error}
        hasReachedPenultimatePage={hasReachedPenultimatePage}
        loading={loading}
        rentals={rentals}
        searchEnabled={false}
        title={title}
      />
      <Box
        alignItems={'flex-end'}
        display={'flex'}
        justifyContent={'flex-end'}
        pb={1}
        pr={1}
      >
        <Typography variant={'h6'}>Total: {totalCount}</Typography>
      </Box>
    </Box>
  );
};
