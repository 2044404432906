import { version, commitHash, buildDate } from '../../generated/version.js'; // This file is generated by the build process.
import React from 'react';

interface Props {}

export const VersionPage: React.FC<Props> = () => {
  return <VersionInfo />;
};

function VersionInfo() {
  return (
    <div>
      <b>Build info</b>
      <br />
      Version: {version}
      <br />
      Commit: {commitHash}
      <br />
      Built: {buildDate}
    </div>
  );
}
