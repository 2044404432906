import React from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '../../webui/Link';
import { Routes } from '../Routes';
import { Product } from '../types/product';

interface Props {
  product: Pick<Product, 'name' | 'id'>;
}

export const ProductLink: React.FC<Props> = ({ product }) => {
  return (
    <Link
      component={RouterLink}
      to={generatePath(Routes.PRODUCT, {
        id: product.id,
      })}
    >
      {product.name}
    </Link>
  );
};
