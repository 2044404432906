import React, { useEffect } from 'react';
import { parseISO } from '../../../common/utils/dateUtils';
import { LoadingSpinnerContainer } from '../../../webui/progress/LoadingSpinnerContainer';
import { useAllRentals } from '../../rentals/gql/useAllRentals';
import { ProductReplacementEvaluation } from './ProductReplacementEvaluation';
import { transformProductReplacerFilterToAllRentalsGQLFilter } from './transformProductReplacerFilterToAllRentalsGQLFilter';
import { type ProductReplacerRentalFilter as ProductReplacerRentalFilterType } from './types';

interface Props {
  filter: ProductReplacerRentalFilterType;
}

export const ProductReplacer: React.FC<Props> = ({ filter }) => {
  const {
    data: { rentals },
    loading,
    error,
    refetch,
  } = useAllRentals({
    variables: {
      filter: transformProductReplacerFilterToAllRentalsGQLFilter(filter),
    },
  });

  useEffect(() => {
    refetch()
      .then(() => {})
      .catch((error) => {
        console.log('---', error, '--- error');
      });
  }, [filter, refetch]);

  if (loading) {
    return <LoadingSpinnerContainer />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <ProductReplacementEvaluation
      endDate={parseISO(filter.endDate)}
      fromDate={parseISO(filter.fromDate)}
      rentals={rentals}
    />
  );
};
