import { connectAuthEmulator, getAuth } from 'firebase/auth';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppWithProviders } from './AppWithProviders';
import { initializeFirebase } from './common/auth/firebaseConfig';
import { initI18N } from './i18n';
import './index.css';
import 'leaflet/dist/leaflet.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';
import { VersionPage } from './features/meta/Version';

initializeFirebase();
initI18N();

if (process.env.USE_EMULATOR) {
  connectAuthEmulator(getAuth(), 'http://localhost:9099');
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        {/*Hidden route*/}
        <Route exact path="/meta/version" component={VersionPage} />

        {/*Everything else*/}
        <Route path="/" component={AppWithProviders} />
      </Switch>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
