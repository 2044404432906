import React, { useState } from 'react';
import {
  generatePath,
  Link as RouterLink,
  Route,
  Switch,
  useParams,
} from 'react-router-dom';
import { BoxLink } from '../../common/router_links/BoxLink';
import { Routes } from '../../common/Routes';
import { toDisplayDateTime, toDisplayTime } from '../../common/utils/dateUtils';
import { Box } from '../../webui/Box';
import { Button } from '../../webui/Button';
import { Container } from '../../webui/Container';
import { Link } from '../../webui/Link';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Typography } from '../../webui/Typography';
import { CompartmentPage } from '../compartment/CompartmentPage';
import { BoxColor } from './BoxColor';
import { BoxPageBatteryReadings } from './BoxPageBatteryReadings';
import { CurrentStatusTable } from './current-status/CurrentStatusTable';
import { EditBoxDrawer } from './edit/EditBoxDrawer';
import { EditCompartmentsDrawer } from './edit/EditCompartmentsDrawer';
import { useBoxById } from './gql/useBoxById';
import { LocationTranslation } from './LocationTranslation';
import { BoxMap } from './map/BoxMap';
import { ProductChange } from './ProductChange';
import { RentalHistoryTable } from './rental-history-table/RentalHistoryTable';
import { useTranslatedLocation } from './utils/useTranslatedLocation';

interface BoxPageProps {}

export const BoxPage: React.FC<BoxPageProps> = () => {
  const { id } = useParams<{ id: string }>();
  const { data: box, loading, error } = useBoxById({ variables: { id } });
  const [openEditBoxDrawer, setOpenEditBoxDrawer] = useState(false);
  const { tLocationDescription, tLocationName } = useTranslatedLocation();
  const onCloseEditBox = () => {
    setOpenEditBoxDrawer(false);
  };

  const onOpenEditBox = () => {
    setOpenEditBoxDrawer(true);
  };

  const [openEditCompartmentsDrawer, setOpenEditCompartmentsDrawer] =
    useState(false);
  const onCloseEditCompartments = () => {
    setOpenEditCompartmentsDrawer(false);
  };

  const onOpenEditCompartments = () => {
    setOpenEditCompartmentsDrawer(true);
  };

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  if (!box) {
    return <div>Could not find box information.</div>;
  }

  return (
    <Container>
      <Paper>
        <Box
          alignItems="flex-start"
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          p={2}
        >
          <Box>
            <BoxLink boxId={id} linkText={box.name} />

            {!box.active && (
              <Typography mt={2} color="error" variant="body2">
                This box is not active
              </Typography>
            )}

            {box.deprecated && (
              <Typography mt={2} color="warning.main" variant="body2">
                This box is deprecated and not for use anywhere
              </Typography>
            )}

            {box.location && (
              <Box mt={3}>
                <Typography variant="body2">
                  Name: {box.location.name}
                </Typography>
                <Typography mt={0.5} variant="body2">
                  Description: {box.location.description}
                </Typography>
                <Typography mt={0.5} variant="body2">
                  Municipality: {box.location.municipality}
                </Typography>
                <Typography mt={0.5} variant="body2">
                  Locality: {box.location.locality}
                </Typography>

                {['en', 'sv', 'no', 'da'].map((lng) => (
                  <LocationTranslation
                    key={lng}
                    description={tLocationDescription(box, lng)}
                    lng={lng as 'da' | 'en' | 'no' | 'sv'}
                    name={tLocationName(box, lng)}
                  />
                ))}
              </Box>
            )}

            <Typography mt={3} variant="body2">
              Open Hours: {toDisplayTime(box.openStartTime)} -{' '}
              {toDisplayTime(box.openEndTime)}
            </Typography>
            <Typography mt={1} variant="body2">
              Price Weight: {box.priceWeight.name}
            </Typography>

            <Box mt={1} display="flex" alignItems="center">
              <Typography variant="body2">Color:</Typography>
              <Box ml={1}>
                <BoxColor color={box.color} />
              </Box>
            </Box>

            {box.sponsor && (
              <Typography mt={1} variant="body2">
                Sponsored by:{' '}
                <Link
                  component={RouterLink}
                  to={generatePath(Routes.SPONSOR, { id: box.sponsor.id })}
                  underline="hover"
                >
                  {box.sponsor.name}
                </Link>
              </Typography>
            )}

            {/* Initial Activation Time */}
            {box.initialActivationTime && (
              <Typography mt={1} variant="body2">
                Initially Activated:{' '}
                {toDisplayDateTime(box.initialActivationTime)}
              </Typography>
            )}
          </Box>

          <Box
            mt={{ xs: 3, md: 0 }}
            display="flex"
            flexDirection="column"
            alignItems={{ xs: 'stretch', md: 'flex-end' }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={onOpenEditBox}
              sx={{ mb: 2, width: '100%' }}
            >
              Edit
            </Button>
            <Button
              variant="outlined"
              onClick={onOpenEditCompartments}
              sx={{ width: '100%' }}
            >
              Edit Compartments
            </Button>
          </Box>
        </Box>
      </Paper>
      <Box mt={2}>
        <Paper>
          <BoxMap
            boxMarkers={
              box.location.coordinates
                ? [
                    {
                      active: box.active,
                      color: box.color,
                      coordinates: box.location.coordinates,
                      id: box.id,
                      title: box.name,
                    },
                  ]
                : []
            }
          />
        </Paper>
      </Box>
      <Box mt={2}>
        <Switch>
          <Route path={Routes.COMPARTMENT}>
            <CompartmentPage />
          </Route>
          <Route path="/">
            <Box my={2}>
              <Paper>
                <CurrentStatusTable boxId={box.id} />
              </Paper>
            </Box>
            <Box my={2}>
              <Paper>
                <RentalHistoryTable boxId={box.id} />
              </Paper>
            </Box>
            <Box my={2}>
              <Paper>
                <BoxPageBatteryReadings boxId={box.id} />
              </Paper>
            </Box>
            <Box my={2}>
              <Paper>
                <ProductChange boxId={box.id} />
              </Paper>
            </Box>
          </Route>
        </Switch>
      </Box>
      <EditBoxDrawer
        box={box}
        open={openEditBoxDrawer}
        onClose={onCloseEditBox}
      />
      <EditCompartmentsDrawer
        box={box}
        open={openEditCompartmentsDrawer}
        onClose={onCloseEditCompartments}
      />
    </Container>
  );
};
