import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { CompartmentLink } from '../../common/router_links/CompartmentLink';
import { ProductLink } from '../../common/router_links/ProductLink';
import { Rental } from '../../common/types/rental';
import { compareAsc, format, parseISO } from '../../common/utils/dateUtils';
import { getProductChangesInCompartment } from './utils/getProductChangesInCompartment';

interface ProductChangeListProps {
  rentals: Rental[];
}

export const ProductChangeTable: React.FC<ProductChangeListProps> = ({
  rentals,
}) => {
  // Get all changes from the provided rentals data
  const changes = getProductChangesInCompartment(rentals);

  // Sort changes by compartment ID (string sort)
  const sortedChanges = changes.sort((a, b) => {
    const compartmentNameComparison =
      parseInt(a.compartmentName, 10) - parseInt(b.compartmentName, 10);
    if (compartmentNameComparison !== 0) return compartmentNameComparison;
    return compareAsc(
      parseISO(a.lastRentalOfOldProduct),
      parseISO(b.lastRentalOfOldProduct)
    );
  });

  return (
    <TableContainer>
      <Typography variant="h6" gutterBottom>
        Product Changes by Compartment
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Compartment</TableCell>
            <TableCell>Old Product</TableCell>
            <TableCell>New Product</TableCell>
            <TableCell>Last time old product was used</TableCell>
            <TableCell>First time new product was used</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedChanges.map((change, index) => (
            <TableRow key={index}>
              <TableCell>
                <CompartmentLink
                  boxId={change.boxId}
                  compartmentId={change.compartmentId}
                  linkText={change.compartmentName}
                />
              </TableCell>
              <TableCell>
                <ProductLink product={change.oldProduct} />
              </TableCell>
              <TableCell>
                <ProductLink product={change.newProduct} />
              </TableCell>
              <TableCell>
                {format(
                  parseISO(change.lastRentalOfOldProduct),
                  'yyyy-MM-dd HH:mm'
                )}
              </TableCell>
              <TableCell>
                {format(
                  parseISO(change.firstRentalOfNewProduct),
                  'yyyy-MM-dd HH:mm'
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProductChangeTable;
