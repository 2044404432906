import React, { useState } from 'react';
import { generatePath } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { CompartmentLink } from '../../common/router_links/CompartmentLink';
import { ProductLink } from '../../common/router_links/ProductLink';
import { UserLink } from '../../common/router_links/UserLink';
import { Routes } from '../../common/Routes';
import { toDisplayDateTime } from '../../common/utils/dateUtils';
import { resolveRentalTypeDisplayName } from '../../common/utils/ResolveRentalTypeDisplayName';
import { Box } from '../../webui/Box';
import { Container } from '../../webui/Container';
import { DataList, DataListItem } from '../../webui/DataList';
import { Link } from '../../webui/Link';
import { Paper } from '../../webui/Paper';
import { LoadingSpinnerContainer } from '../../webui/progress/LoadingSpinnerContainer';
import { Rating } from '../../webui/Rating';
import { ToggleOpen } from '../../webui/ToggleOpen';
import { Typography } from '../../webui/Typography';
import { ChargeRentalButton } from './ChargeRentalButton';
import { useRentalById } from './gql/useRentalById';
import { PaymentChargeInformation } from './PaymentChargeInformation';
import { RentalImage } from './RentalImage';
import { StopRentalButton } from './StopRentalButton';

interface Props {}

export const RentalPage: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();

  const [showChargeRental, setShowChargeRental] = useState(false);

  const {
    data: rental,
    loading,
    error,
  } = useRentalById({
    variables: { id },
  });

  if (loading) {
    return <LoadingSpinnerContainer />;
  }
  if (error) {
    return <div>{error.message}</div>;
  }

  if (!rental) {
    return <div>Could not find rental information.</div>;
  }

  const items: DataListItem[] = [
    { label: 'ID', value: rental.id },
    {
      label: 'Box',
      value: (
        <Link
          component={RouterLink}
          to={generatePath(Routes.BOX, {
            id: rental.box.id,
          })}
        >
          {rental.box.name}
        </Link>
      ),
    },
    {
      label: 'Compartment',
      value: (
        <CompartmentLink
          boxId={rental.box.id}
          compartmentId={rental.compartment.id}
          linkText={rental.compartment.name ?? ''}
        />
      ),
    },
    {
      label: 'Product',
      value: <ProductLink product={rental.product} />,
    },
    {
      label: 'Product status',
      value: rental.productStatus,
    },
    {
      label: 'Product status text',
      value: rental.productStatusText,
    },
    {
      label: 'Aborted',
      value: rental.aborted ? 'Yes' : 'No',
    },
    {
      label: 'Rent by',
      value: (
        <UserLink
          userId={rental.rentBy.id}
          linkText={rental.rentBy.email || 'Anonymized user'}
        />
      ),
    },
    {
      label: 'Rent start',
      value: rental.rentStart ? toDisplayDateTime(rental.rentStart) : undefined,
    },
    {
      label: 'Rent end',
      value: rental.rentEnd ? toDisplayDateTime(rental.rentEnd) : undefined,
    },
    {
      label: 'Rent time',
      value: rental.rentTime ? rental.rentTime : '',
    },
    {
      label: 'Rental type',
      value: resolveRentalTypeDisplayName(rental.product.rentalType),
    },
    {
      label: 'Successful openings',
      value: rental.numberOfSuccessfulOpenings,
    },
    {
      label: 'Failed openings',
      value: rental.numberOfFailedOpenings,
    },
    {
      label: 'Rating',
      value: rental.review?.rating ? (
        <Rating readOnly={true} value={rental.review.rating} />
      ) : null,
    },
    {
      label: 'Feedback',
      value: rental.review?.freeText,
    },
  ];

  const onToggle = (show: boolean) => {
    setShowChargeRental(show);
  };
  return (
    <Container>
      <Paper>
        <Box
          p={2}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Box>
            <DataList header={'Rental'} items={items} />
            {rental.paymentChargeInformation && (
              <Box mt={2}>
                <Typography variant={'h4'}>Payment info</Typography>
                <Box mt={2}>
                  <PaymentChargeInformation
                    paymentChargeInformation={rental.paymentChargeInformation}
                  />
                </Box>
              </Box>
            )}
            {rental.review?.ratingQuestionAnswers[0]?.rating && (
              <Typography>
                review: {rental.review?.ratingQuestionAnswers[0]?.rating}
              </Typography>
            )}
            <Box
              flexDirection={'row'}
              display={'flex'}
              onClick={() => onToggle(!showChargeRental)}
            >
              <Typography>Stop / charge rental</Typography>
              <ToggleOpen open={showChargeRental} onToggle={onToggle} />
            </Box>
            {showChargeRental && (
              <>
                <StopRentalButton rentalId={rental.id} />
                <ChargeRentalButton rentalId={rental.id} />
              </>
            )}
          </Box>
          <Box
            display={'flex'}
            justifyContent={'flex-end'}
            mt={2}
            height={'400px'}
            width={'400px'}
          >
            <RentalImage id={id} />
          </Box>
        </Box>
      </Paper>
    </Container>
  );
};
