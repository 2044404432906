import React from 'react';
import { BoxLink } from '../../common/router_links/BoxLink';
import { UserLink } from '../../common/router_links/UserLink';
import { BatteryReading } from '../../common/types/batteryReading';
import { toDisplayDateTime } from '../../common/utils/dateUtils';
import { resolveBoxDisplayName } from '../../common/utils/resolveBoxDisplayName';
import { SortablePaginatedTable } from '../../webui/table/sortable-paginated-table/SortablePaginatedTable';
import { TableHeadCell } from '../../webui/table/sortable-paginated-table/TableHeadCell';

interface Props {
  readings: BatteryReading[];
}

interface LatestBatteryReadingPerBoxData {
  id: string;
  boxId: string;
  boxName: string;
  date: string;
  user: string;
  voltage: number;
}

const batteryReadingHeadCells: TableHeadCell<LatestBatteryReadingPerBoxData>[] =
  [
    {
      id: 'boxName',
      label: 'Box',
      render: (row) => <BoxLink boxId={row.boxId} linkText={row.boxName} />,
    },
    {
      id: 'voltage',
      label: 'Voltage',
      render: (row) => row.voltage,
    },
    {
      id: 'date',
      label: 'Date',
      render: (row) => toDisplayDateTime(row.date),
    },
    {
      id: 'user',
      label: 'User',
      render: (row) => (
        <UserLink userId={row.user} linkText={row.user.slice(0, 5) + '...'} />
      ),
    },
  ];

const INITIAL_ROWS_PER_PAGE = 20;

const createRows = (
  readings: BatteryReading[]
): LatestBatteryReadingPerBoxData[] => {
  return readings.map((reading) => ({
    id: reading.id,
    boxId: reading.box.id,
    boxName: resolveBoxDisplayName(reading.box),
    date: reading.createdAt,
    user: reading.userId,
    voltage: reading.voltage / 1000,
  }));
};

export const LatestBatteryReadingPerBoxTable: React.FC<Props> = ({
  readings,
}) => {
  return (
    <SortablePaginatedTable
      title={'Latest battery reading per box'}
      initialRowsPerPage={INITIAL_ROWS_PER_PAGE}
      initialOrder={'asc'}
      initialOrderByKey={'date'}
      tableHeadCells={batteryReadingHeadCells}
      rows={createRows(readings)}
    />
  );
};
