import { Rental } from '../../../common/types/rental';
import {
  EvaluatableRental,
  normalize,
  transformToEvaluatableRentals,
} from './transformToEvaluatableRentals';

export const getEvaluatedRentalsData = (
  rentals: Rental[],
  fromDate: Date,
  endDate: Date
): (EvaluatableRental & { replacementScore: number })[] => {
  const evaluatableRentals = transformToEvaluatableRentals(
    rentals,
    fromDate,
    endDate
  );

  const minMax = {
    rentals: [Infinity, -Infinity],
    totalRentalTime: [Infinity, -Infinity],
    rentalsPerDay: [Infinity, -Infinity],
    averageRating: [1, 5],
    daysSinceLastRental: [Infinity, -Infinity],
  };

  evaluatableRentals.forEach((r) => {
    minMax.rentals = [
      Math.min(minMax.rentals[0], r.rentals),
      Math.max(minMax.rentals[1], r.rentals),
    ];
    minMax.totalRentalTime = [
      Math.min(minMax.totalRentalTime[0], r.totalRentalTime),
      Math.max(minMax.totalRentalTime[1], r.totalRentalTime),
    ];
    minMax.rentalsPerDay = [
      Math.min(minMax.rentalsPerDay[0], r.rentalsPerDay),
      Math.max(minMax.rentalsPerDay[1], r.rentalsPerDay),
    ];
    minMax.daysSinceLastRental = [
      Math.min(minMax.daysSinceLastRental[0], r.daysSinceLastRental),
      Math.max(minMax.daysSinceLastRental[1], r.daysSinceLastRental),
    ];
  });

  return evaluatableRentals
    .map((r) => {
      const score =
        (1 - normalize(r.rentals, minMax.rentals[0], minMax.rentals[1])) * 0.3 +
        (1 -
          normalize(
            r.totalRentalTime,
            minMax.totalRentalTime[0],
            minMax.totalRentalTime[1]
          )) *
          0.2 +
        (1 -
          normalize(
            r.rentalsPerDay,
            minMax.rentalsPerDay[0],
            minMax.rentalsPerDay[1]
          )) *
          0.3 +
        (1 -
          normalize(
            r.averageRating,
            minMax.averageRating[0],
            minMax.averageRating[1]
          )) *
          0.1 +
        normalize(
          r.daysSinceLastRental,
          minMax.daysSinceLastRental[0],
          minMax.daysSinceLastRental[1]
        ) *
          0.1;

      return { ...r, replacementScore: score };
    })
    .sort((a, b) => b.replacementScore! - a.replacementScore!);
};
