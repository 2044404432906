import React, { useState } from 'react';
import { addDays, formatDate, subDays } from '../../../common/utils/dateUtils';
import { Box } from '../../../webui/Box';
import { Container } from '../../../webui/Container';
import { LoadingSpinnerContainer } from '../../../webui/progress/LoadingSpinnerContainer';
import { useAllBoxesWithoutRentals } from '../../box/gql/useAllBoxesWithoutRentals';
import { ProductReplacer } from './ProductReplacer';
import { ProductReplacerRentalFilter } from './ProductReplacerRentalFilter';
import { type ProductReplacerRentalFilter as ProductReplacerRentalFilterType } from './types';

interface Props {}

const initialFromDate = formatDate(subDays(new Date(), 14));
const initialEndDate = formatDate(addDays(new Date(), 1));

export const ProductReplacerPage: React.FC<Props> = () => {
  const { data: allBoxes, loading: allBoxesLoading } =
    useAllBoxesWithoutRentals();

  const [filter, setFilter] = useState<ProductReplacerRentalFilterType>({
    selectedBoxes: [],
    fromDate: initialFromDate,
    endDate: initialEndDate,
  });

  const onFilterChange = (filter: ProductReplacerRentalFilterType) => {
    setFilter(filter);
  };

  if (allBoxesLoading) {
    return <LoadingSpinnerContainer />;
  }

  return (
    <Container>
      <ProductReplacerRentalFilter
        allBoxes={allBoxes}
        initialFilter={filter}
        onFilterChange={onFilterChange}
      />
      <Box mt={2} />
      <ProductReplacer filter={filter} />
    </Container>
  );
};
