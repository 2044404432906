import React, { useCallback } from 'react';
import { Box } from '../../webui/Box';
import { Paper } from '../../webui/Paper';
import { Typography } from '../../webui/Typography';
import { JSONEditor } from './JSONEditor';

interface Props {
  hasChanges?: boolean;
  json?: Object | undefined;
  label?: string;
  lang: string;
  enabled?: boolean;
  onChange: (lang: string) => (json: any) => void;
}

export const TranslationEditor: React.FC<Props> = ({
  enabled,
  hasChanges,
  json,
  label,
  lang,
  onChange,
}) => {
  const onJsonEditorChange = useCallback(
    (json: any) => {
      return onChange(lang)(json);
    },
    [lang, onChange]
  );
  return (
    <Paper
      key={`json-editor-${lang}`}
      style={{
        display: enabled ? 'block' : 'none',
        margin: 8,
        padding: 8,
        width: '100%',
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
        flexDirection={'row'}
        mb={1}
      >
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Typography variant={'h3'}>{label}</Typography>
          <Typography variant={'h6'}>
            {hasChanges ? ' (has changes)' : null}
          </Typography>
        </Box>
      </Box>
      <JSONEditor json={json} onChange={onJsonEditorChange} />
    </Paper>
  );
};
