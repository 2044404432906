import { BatteryReading } from '../../../common/types/batteryReading';
import { sortWithCreatedAt } from '../../../common/utils/sort/comparators/createdAtComparator';

export interface BatteryPerBoxData {
  date: string;
  user: string;
  voltage: number;
}

export const generateBatteryPerBoxData = (
  batteryReadings: BatteryReading[]
): BatteryPerBoxData[] => {
  return sortWithCreatedAt(batteryReadings).map((reading) => ({
    date: reading.createdAt,
    user: reading.userId,
    voltage: reading.voltage / 1000,
  }));
};
